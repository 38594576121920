import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FirstPersonControls } from 'three/addons/controls/FirstPersonControls.js';

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Object
 */
const geometry = new THREE.BoxGeometry(1, 1, 1)
//const material = new THREE.MeshBasicMaterial({ color: 0xff0000 })
const material = new THREE.MeshPhongMaterial({ color: 0xff0000 });




var torus = new THREE.Scene();
torus.rotation.z = Math.PI  
scene.add(torus)

const p_geometry = new THREE.SphereGeometry( 0.5, 32, 16 ); 
const p_material = new THREE.MeshBasicMaterial( { color: 0xeeeeee } ); 

var p = 0;

const a = 6;
const b = 3;
const k = 0.001;

const scaleFactor = 50; // Adjust this for better visualization

for (let u = 0; u <= 1000 * 2 * Math.PI; u += 0.2) {
  const v = k * u;

  const x = (a + b * Math.cos(u)) * Math.cos(v) * scaleFactor;
  const y = (a + b * Math.cos(u)) * Math.sin(v) * scaleFactor;
  const z = b * Math.sin(u) * scaleFactor;

  var particle = new THREE.Mesh( p_geometry, p_material ); 
  
  particle.dx = Math.random()*2-1
  particle.dy = Math.random()*2-1
  particle.dz = Math.random()*2-1
  particle.theta = Math.random()*2*Math.PI * 0.1
  particle.s = 50


  particle.position.x = x + particle.s*particle.dx*Math.sin(particle.theta)
  particle.position.y = y + particle.s*particle.dy*Math.sin(particle.theta)
  particle.position.z = z + particle.s*particle.dz*Math.sin(particle.theta)
  
  particle.ox = x
  particle.oy = y
  particle.oz = z

  torus.add( particle );
    p++
}
console.log(torus)

function anim(scenex) {
  for (p=0; p<scenex.children.length; p++) {
    var particle = scenex.children[p]
    particle.position.x = particle.ox + particle.s*particle.dx*Math.sin(particle.theta)
    particle.position.y = particle.oy + particle.s*particle.dy*Math.sin(particle.theta)
    particle.position.z = particle.oz + particle.s*particle.dz*Math.sin(particle.theta)
    particle.theta += 0.004
  }
}











/**
 * Camera
 */
const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight)
camera.position.z = 108.29282453443182
camera.position.x = 176.04330146998313 +15 

camera.position.y = 88.84861055868186

camera.rotation.x = 0.8
camera.rotation.y = 0.8760580505981941
camera.rotation.z = 0



scene.add(camera)





/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(window.innerWidth, window.innerHeight)
renderer.render(scene, camera)



// Controller
var t = 0
const controls = new OrbitControls( camera, renderer.domElement );



function animate() {
  t++
  torus.rotation.z -= 0.0006
  anim(torus)
	requestAnimationFrame( animate );
	controls.update();
	renderer.render( scene, camera );
}


// Event listener for window resize
window.addEventListener('resize', onWindowResize);
function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}


animate();